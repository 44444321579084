import { combineReducers } from "redux";
import app from "./app/reducer";
import auth from "./auth/reducer";
import map from "./map/reducer";

export default combineReducers({
  app,
  auth,
  map,
});
