import { BASE_MAP } from "utils/constant";
import {
  SET_LOADING_MAP,
  SET_GEOJSON_DATA,
  SET_CENTER_MAP,
  SET_MAP_DATA,
  SET_MAP_SETTINGS,
  SET_LEGENDS_MAP,
} from "./type";

const initialReducer = {
  map: null,
  loading: false,
  geojson: null,
  legends: [],
  center: [-1.002549378838455, 133.7533570590636],
  settings: {
    basekab: true,
    basemap: BASE_MAP.GOOGLE_MAP,
  },
};

const mapReducer = (state = initialReducer, action) => {
  switch (action.type) {
    case SET_LOADING_MAP:
      return { ...state, loading: action.payload };
    case SET_GEOJSON_DATA:
      return { ...state, geojson: action.payload };
    case SET_CENTER_MAP:
      return { ...state, center: action.payload };
    case SET_MAP_DATA:
      return { ...state, map: action.payload };
    case SET_MAP_SETTINGS:
      return { ...state, settings: action.payload };
    case SET_LEGENDS_MAP:
      return { ...state, legends: action.payload };
    default:
      return state;
  }
};

export default mapReducer;
