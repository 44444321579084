import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useRoutes } from "react-router-dom";
import { setMenu } from "redux/actions";
import { routes } from "./routes";

const Routers = () => {
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.auth);

  const roleID = 1 || 2 || 3;

  const userType = user?.type ?? roleID;

  const filterUsers = [
    ...routes.filter((route) => !route.userType),
    ...routes.filter((route) => route.userType?.includes(userType)),
  ];

  const routing = useRoutes(filterUsers);

  useEffect(() => {
    dispatch(setMenu(routes));
  }, [routes]);

  return routing;
};

export default Routers;
