import { lazy } from "react";
import { Navigate } from "react-router-dom";
import { USER_TYPE } from "utils/constant";

/**
 * PRIVATE ROUTE
 */

const PrivateRoute = lazy(() => import("./private"));

/**
 * AUTH
 *
 */

const LoginPages = lazy(() => import("pages/auth/login"));
const LogoutPages = lazy(() => import("pages/auth/logout"));
const ForgotPasswordPages = lazy(() => import("pages/auth/forgot-password"));

/**
 * PRIVATE PAGES
 *
 */

const DashboardPages = lazy(() => import("pages/dashboard"));

/**
 * PROFILE
 */

const ProfilePages = lazy(() => import("pages/profile"));

/**
 * USER
 */

const ListUser = lazy(() => import("pages/user/list"));
const CreateUser = lazy(() => import("pages/user/create"));
const UpdateUser = lazy(() => import("pages/user/update"));

/**
 * SETTINGS
 */

const PeriodeUpload = lazy(() => import("pages/settings/periode-upload"));

/**
 * MASTER DATA
 */

//OPD

// --> UNIT
const MasterTransaction = lazy(() => import("pages/transaction-data/overview"));
const MasterUnit = lazy(() => import("pages/master-data/opd/unit/list"));
const CreateUnit = lazy(() => import("pages/master-data/opd/unit/create"));
const UpdateUnit = lazy(() => import("pages/master-data/opd/unit/update"));

// --> SUB UNIT
const MasterSubUnit = lazy(() => import("pages/master-data/opd/sub-unit/list"));
const CreateSubUnit = lazy(() =>
  import("pages/master-data/opd/sub-unit/create")
);
const UpdateSubUnit = lazy(() =>
  import("pages/master-data/opd/sub-unit/update")
);

//URUSAN

// --> URUSAN
const MasterUrusan = lazy(() => import("pages/master-data/urusan/urusan/list"));
const CreateUrusan = lazy(() =>
  import("pages/master-data/urusan/urusan/create")
);
const UpdateUrusan = lazy(() =>
  import("pages/master-data/urusan/urusan/update")
);

// --> BIDANG
const MasterBidang = lazy(() => import("pages/master-data/urusan/bidang/list"));
const CreateBidang = lazy(() =>
  import("pages/master-data/urusan/bidang/create")
);
const UpdateBidang = lazy(() =>
  import("pages/master-data/urusan/bidang/update")
);

// --> PROGRAM
const MasterProgram = lazy(() =>
  import("pages/master-data/urusan/program/list")
);
const CreateProgram = lazy(() =>
  import("pages/master-data/urusan/program/create")
);
const UpdateProgram = lazy(() =>
  import("pages/master-data/urusan/program/update")
);

// --> KEGIATAN
const MasterKegiatan = lazy(() =>
  import("pages/master-data/urusan/kegiatan/list")
);
const CreateKegiatan = lazy(() =>
  import("pages/master-data/urusan/kegiatan/create")
);
const UpdateKegiatan = lazy(() =>
  import("pages/master-data/urusan/kegiatan/update")
);

// --> SUB KEGIATAN
const MasterSubKegiatan = lazy(() =>
  import("pages/master-data/urusan/sub-kegiatan/list")
);
const CreateSubKegiatan = lazy(() =>
  import("pages/master-data/urusan/sub-kegiatan/create")
);
const UpdateSubKegiatan = lazy(() =>
  import("pages/master-data/urusan/sub-kegiatan/update")
);

//NERACA

// --> AKUN
const MasterAkun = lazy(() => import("pages/master-data/neraca/akun/list"));
const CreateAkun = lazy(() => import("pages/master-data/neraca/akun/create"));
const UpdateAkun = lazy(() => import("pages/master-data/neraca/akun/update"));

// --> KELOMPOK
const MasterKelompok = lazy(() =>
  import("pages/master-data/neraca/kelompok/list")
);
const CreateKelompok = lazy(() =>
  import("pages/master-data/neraca/kelompok/create")
);
const UpdateKelompok = lazy(() =>
  import("pages/master-data/neraca/kelompok/update")
);

// --> JENIS

const MasterJenis = lazy(() => import("pages/master-data/neraca/jenis/list"));
const CreateJenis = lazy(() => import("pages/master-data/neraca/jenis/create"));
const UpdateJenis = lazy(() => import("pages/master-data/neraca/jenis/update"));

// --> OBJEK
const MasterNeracaObjek = lazy(() =>
  import("pages/master-data/neraca/objek/list")
);
const CreateNeracaObjek = lazy(() =>
  import("pages/master-data/neraca/objek/create")
);
const UpdateNeracaObjek = lazy(() =>
  import("pages/master-data/neraca/objek/update")
);

// --> RINCIAN OBJEK

const MasterRincianObjek = lazy(() =>
  import("pages/master-data/neraca/rincian-objek/list")
);
const CreateRincianObjek = lazy(() =>
  import("pages/master-data/neraca/rincian-objek/create")
);
const UpdateRincianObjek = lazy(() =>
  import("pages/master-data/neraca/rincian-objek/update")
);

// --> SUB RINCIAN OBJEK

const MasterSubRincianObjek = lazy(() =>
  import("pages/master-data/neraca/sub-rincian-objek/list")
);
const CreateSubRincianObjek = lazy(() =>
  import("pages/master-data/neraca/sub-rincian-objek/create")
);
const UpdateSubRincianObjek = lazy(() =>
  import("pages/master-data/neraca/sub-rincian-objek/update")
);

/**
 * MAPS
 */

const MonitoringKegiatanMap = lazy(() =>
  import("pages/map/monitoring-kegiatan")
);
const SeeLocationAnggaran = lazy(() =>
  import("pages/map/see-location-anggaran")
);

// SUMBER DANA
// --> SUMBER DANA

const MasterSumberDana = lazy(() =>
  import("pages/master-data/sumber-dana/sumber-dana/list")
);
const CreateSumberDana = lazy(() =>
  import("pages/master-data/sumber-dana/sumber-dana/create")
);
const UpdateSumberDana = lazy(() =>
  import("pages/master-data/sumber-dana/sumber-dana/update")
);

// --> KELOMPOK
const MasterSumberDanaKelompok = lazy(() =>
  import("pages/master-data/sumber-dana/kelompok/list")
);
const CreateSumberDanaKelompok = lazy(() =>
  import("pages/master-data/sumber-dana/kelompok/create")
);
const UpdateSumberDanaKelompok = lazy(() =>
  import("pages/master-data/sumber-dana/kelompok/update")
);

// --> JENIS
const MasterSumberDanaJenis = lazy(() =>
  import("pages/master-data/sumber-dana/jenis/list")
);
const CreateSumberDanaJenis = lazy(() =>
  import("pages/master-data/sumber-dana/jenis/create")
);
const UpdateSumberDanaJenis = lazy(() =>
  import("pages/master-data/sumber-dana/jenis/update")
);

// --> OBJEK
const MasterSumberDanaObjek = lazy(() =>
  import("pages/master-data/sumber-dana/objek/list")
);
const CreateSumberDanaObjek = lazy(() =>
  import("pages/master-data/sumber-dana/objek/create")
);
const UpdateSumberDanaObjek = lazy(() =>
  import("pages/master-data/sumber-dana/objek/update")
);

// --> RINCIAN OBJEK
const MasterSumberDanaRincianObjek = lazy(() =>
  import("pages/master-data/sumber-dana/rincian-objek/list")
);
const CreateSumberDanaRincianObjek = lazy(() =>
  import("pages/master-data/sumber-dana/rincian-objek/create")
);
const UpdateSumberDanaRincianObjek = lazy(() =>
  import("pages/master-data/sumber-dana/rincian-objek/update")
);

// --> SUB RINCIAN OBJEK
const MasterSumberDanaSubRincianObjek = lazy(() =>
  import("pages/master-data/sumber-dana/sub-rincian-objek/list")
);
const CreateSumberDanaSubRincianObjek = lazy(() =>
  import("pages/master-data/sumber-dana/sub-rincian-objek/create")
);
const UpdateSumberDanaSubRincianObjek = lazy(() =>
  import("pages/master-data/sumber-dana/sub-rincian-objek/update")
);

/**
 * ANGGARAN
 */

const AnggaranPages = lazy(() => import("pages/anggaran/anggaran"));
const ListAktivitasSubKegiatan = lazy(() =>
  import("pages/anggaran/subkegiatan/list")
);
const EditAktivitasSubKegiatan = lazy(() =>
  import("pages/anggaran/subkegiatan/edit")
);

/**
 * PELAPORAN
 *
 */

const PelaporanSubKegiatan = lazy(() => import("pages/pelaporan/sub-kegiatan"));
const PelaporanProgresBulananList = lazy(() =>
  import("pages/pelaporan/progress-bulanan/list")
);
const PelaporanProgresBulananAdd = lazy(() =>
  import("pages/pelaporan/progress-bulanan/create")
);
const PelaporanProgresBulananEdit = lazy(() =>
  import("pages/pelaporan/progress-bulanan/edit")
);

/**
 * FORMULIR DUMMY
 */

const FormulirE40 = lazy(() => import("pages/formulir/e-40"));
const FormulirE60 = lazy(() => import("pages/formulir/e-60"));

/**
 * ERROR PAGES
 *
 */

const NotFoundPage = lazy(() => import("pages/errors/404"));

export const routes = [
  {
    exact: true,
    path: "*",
    element: <NotFoundPage />,
  },
  {
    exact: true,
    path: "/",
    element: <Navigate to="/dashboard" />,
  },
  {
    exact: true,
    path: "/login",
    element: <LoginPages />,
  },
  {
    exact: true,
    path: "/logout",
    element: (
      <PrivateRoute
        userType={[
          USER_TYPE.SUPER_USER,
          USER_TYPE.ADMIN_BAPPEDA,
          USER_TYPE.USER_OPD,
        ]}
        withLayout={false}
      >
        <LogoutPages />
      </PrivateRoute>
    ),
  },
  {
    exact: true,
    path: "/forgot-password",
    element: <ForgotPasswordPages />,
  },
  {
    exact: true,
    path: "/profile",
    userType: [
      USER_TYPE.SUPER_USER,
      USER_TYPE.ADMIN_BAPPEDA,
      USER_TYPE.USER_OPD,
    ],
    element: (
      <PrivateRoute
        userType={[
          USER_TYPE.SUPER_USER,
          USER_TYPE.ADMIN_BAPPEDA,
          USER_TYPE.USER_OPD,
        ]}
      >
        <ProfilePages />
      </PrivateRoute>
    ),
  },
  {
    exact: true,
    path: "/monitoring-kegiatan",
    userType: [
      USER_TYPE.SUPER_USER,
      USER_TYPE.ADMIN_BAPPEDA,
      USER_TYPE.USER_OPD,
    ],
    element: (
      <PrivateRoute
        withLayout={false}
        userType={[
          USER_TYPE.SUPER_USER,
          USER_TYPE.ADMIN_BAPPEDA,
          USER_TYPE.USER_OPD,
        ]}
      >
        <MonitoringKegiatanMap />
      </PrivateRoute>
    ),
  },
  {
    exact: true,
    path: "/anggaran/:id/lihat-lokasi",
    userType: [
      USER_TYPE.SUPER_USER,
      USER_TYPE.ADMIN_BAPPEDA,
      USER_TYPE.USER_OPD,
    ],
    element: (
      <PrivateRoute
        withLayout={false}
        userType={[
          USER_TYPE.SUPER_USER,
          USER_TYPE.ADMIN_BAPPEDA,
          USER_TYPE.USER_OPD,
        ]}
      >
        <SeeLocationAnggaran />
      </PrivateRoute>
    ),
  },
  {
    name: "Dashboard",
    exact: true,
    path: "/dashboard",
    icon: "bi bi-briefcase",
    userType: [
      USER_TYPE.SUPER_USER,
      USER_TYPE.ADMIN_BAPPEDA,
      USER_TYPE.USER_OPD,
    ],
    element: (
      <PrivateRoute
        userType={[
          USER_TYPE.SUPER_USER,
          USER_TYPE.ADMIN_BAPPEDA,
          USER_TYPE.USER_OPD,
        ]}
      >
        <DashboardPages />
      </PrivateRoute>
    ),
  },
  {
    name: "Data Master",
    icon: "bi bi-columns-gap",
    userType: [USER_TYPE.SUPER_USER, USER_TYPE.ADMIN_BAPPEDA],
    children: [
      {
        name: "OPD",
        exact: true,
        children: [
          {
            name: "Unit",
            exact: true,
            path: "/master-data-opd/unit",
            element: (
              <PrivateRoute
                userType={[USER_TYPE.SUPER_USER, USER_TYPE.ADMIN_BAPPEDA]}
              >
                <MasterUnit />
              </PrivateRoute>
            ),
          },
          {
            exact: true,
            path: "/master-data-opd/unit/tambah",
            element: (
              <PrivateRoute
                userType={[USER_TYPE.SUPER_USER, USER_TYPE.ADMIN_BAPPEDA]}
              >
                <CreateUnit />
              </PrivateRoute>
            ),
          },
          {
            exact: true,
            path: "/master-data-opd/unit/ubah/:id",
            element: (
              <PrivateRoute
                userType={[USER_TYPE.SUPER_USER, USER_TYPE.ADMIN_BAPPEDA]}
              >
                <UpdateUnit />
              </PrivateRoute>
            ),
          },
          {
            name: "Sub Unit",
            exact: true,
            path: "/master-data-opd/sub-unit",
            element: (
              <PrivateRoute
                userType={[USER_TYPE.SUPER_USER, USER_TYPE.ADMIN_BAPPEDA]}
              >
                <MasterSubUnit />
              </PrivateRoute>
            ),
          },
          {
            exact: true,
            path: "/master-data-opd/sub-unit/tambah",
            element: (
              <PrivateRoute
                userType={[USER_TYPE.SUPER_USER, USER_TYPE.ADMIN_BAPPEDA]}
              >
                <CreateSubUnit />
              </PrivateRoute>
            ),
          },
          {
            exact: true,
            path: "/master-data-opd/sub-unit/ubah/:id",
            element: (
              <PrivateRoute
                userType={[USER_TYPE.SUPER_USER, USER_TYPE.ADMIN_BAPPEDA]}
              >
                <UpdateSubUnit />
              </PrivateRoute>
            ),
          },
        ],
      },
      {
        name: "Urusan",
        exact: true,
        children: [
          {
            name: "Urusan",
            exact: true,
            path: "/master-data-urusan/urusan",
            element: (
              <PrivateRoute
                userType={[USER_TYPE.SUPER_USER, USER_TYPE.ADMIN_BAPPEDA]}
              >
                <MasterUrusan />
              </PrivateRoute>
            ),
          },
          {
            exact: true,
            path: "/master-data-urusan/urusan/tambah",
            element: (
              <PrivateRoute
                userType={[USER_TYPE.SUPER_USER, USER_TYPE.ADMIN_BAPPEDA]}
              >
                <CreateUrusan />
              </PrivateRoute>
            ),
          },
          {
            exact: true,
            path: "/master-data-urusan/urusan/ubah/:id",
            element: (
              <PrivateRoute
                userType={[USER_TYPE.SUPER_USER, USER_TYPE.ADMIN_BAPPEDA]}
              >
                <UpdateUrusan />
              </PrivateRoute>
            ),
          },
          {
            name: "Bidang",
            exact: true,
            path: "/master-data-urusan/bidang",
            element: (
              <PrivateRoute
                userType={[USER_TYPE.SUPER_USER, USER_TYPE.ADMIN_BAPPEDA]}
              >
                <MasterBidang />
              </PrivateRoute>
            ),
          },
          {
            exact: true,
            path: "/master-data-urusan/bidang/tambah",
            element: (
              <PrivateRoute
                userType={[USER_TYPE.SUPER_USER, USER_TYPE.ADMIN_BAPPEDA]}
              >
                <CreateBidang />
              </PrivateRoute>
            ),
          },
          {
            exact: true,
            path: "/master-data-urusan/bidang/ubah/:id",
            element: (
              <PrivateRoute
                userType={[USER_TYPE.SUPER_USER, USER_TYPE.ADMIN_BAPPEDA]}
              >
                <UpdateBidang />
              </PrivateRoute>
            ),
          },
          {
            name: "Program",
            exact: true,
            path: "/master-data-urusan/program",
            element: (
              <PrivateRoute
                userType={[USER_TYPE.SUPER_USER, USER_TYPE.ADMIN_BAPPEDA]}
              >
                <MasterProgram />
              </PrivateRoute>
            ),
          },
          {
            exact: true,
            path: "/master-data-urusan/program/tambah",
            element: (
              <PrivateRoute
                userType={[USER_TYPE.SUPER_USER, USER_TYPE.ADMIN_BAPPEDA]}
              >
                <CreateProgram />
              </PrivateRoute>
            ),
          },
          {
            exact: true,
            path: "/master-data-urusan/program/ubah/:id",
            element: (
              <PrivateRoute
                userType={[USER_TYPE.SUPER_USER, USER_TYPE.ADMIN_BAPPEDA]}
              >
                <UpdateProgram />
              </PrivateRoute>
            ),
          },
          //KEGIATAN
          {
            name: "Kegiatan",
            exact: true,
            path: "/master-data-urusan/kegiatan",
            element: (
              <PrivateRoute
                userType={[USER_TYPE.SUPER_USER, USER_TYPE.ADMIN_BAPPEDA]}
              >
                <MasterKegiatan />
              </PrivateRoute>
            ),
          },
          {
            exact: true,
            path: "/master-data-urusan/kegiatan/tambah",
            element: (
              <PrivateRoute
                userType={[USER_TYPE.SUPER_USER, USER_TYPE.ADMIN_BAPPEDA]}
              >
                <CreateKegiatan />
              </PrivateRoute>
            ),
          },
          {
            exact: true,
            path: "/master-data-urusan/kegiatan/ubah/:id",
            element: (
              <PrivateRoute
                userType={[USER_TYPE.SUPER_USER, USER_TYPE.ADMIN_BAPPEDA]}
              >
                <UpdateKegiatan />
              </PrivateRoute>
            ),
          },
          //SUB KEGIATAN
          {
            name: "Sub Kegiatan",
            exact: true,
            path: "/master-data-urusan/sub-kegiatan",
            element: (
              <PrivateRoute
                userType={[USER_TYPE.SUPER_USER, USER_TYPE.ADMIN_BAPPEDA]}
              >
                <MasterSubKegiatan />
              </PrivateRoute>
            ),
          },
          {
            exact: true,
            path: "/master-data-urusan/sub-kegiatan/tambah",
            element: (
              <PrivateRoute
                userType={[USER_TYPE.SUPER_USER, USER_TYPE.ADMIN_BAPPEDA]}
              >
                <CreateSubKegiatan />
              </PrivateRoute>
            ),
          },
          {
            exact: true,
            path: "/master-data-urusan/sub-kegiatan/ubah/:id",
            element: (
              <PrivateRoute
                userType={[USER_TYPE.SUPER_USER, USER_TYPE.ADMIN_BAPPEDA]}
              >
                <UpdateSubKegiatan />
              </PrivateRoute>
            ),
          },
        ],
      },
      {
        name: "Rekening Neraca",
        exact: true,
        children: [
          {
            name: "Akun",
            exact: true,
            path: "/master-data-neraca/akun",
            element: (
              <PrivateRoute
                userType={[USER_TYPE.SUPER_USER, USER_TYPE.ADMIN_BAPPEDA]}
              >
                <MasterAkun />
              </PrivateRoute>
            ),
          },
          {
            exact: true,
            path: "/master-data-neraca/akun/tambah",
            element: (
              <PrivateRoute
                userType={[USER_TYPE.SUPER_USER, USER_TYPE.ADMIN_BAPPEDA]}
              >
                <CreateAkun />
              </PrivateRoute>
            ),
          },
          {
            exact: true,
            path: "/master-data-neraca/akun/ubah/:id",
            element: (
              <PrivateRoute
                userType={[USER_TYPE.SUPER_USER, USER_TYPE.ADMIN_BAPPEDA]}
              >
                <UpdateAkun />
              </PrivateRoute>
            ),
          },
          // KELOMPOK
          {
            name: "Kelompok",
            exact: true,
            path: "/master-data-neraca/kelompok",
            element: (
              <PrivateRoute
                userType={[USER_TYPE.SUPER_USER, USER_TYPE.ADMIN_BAPPEDA]}
              >
                <MasterKelompok />
              </PrivateRoute>
            ),
          },
          {
            exact: true,
            path: "/master-data-neraca/kelompok/tambah",
            element: (
              <PrivateRoute
                userType={[USER_TYPE.SUPER_USER, USER_TYPE.ADMIN_BAPPEDA]}
              >
                <CreateKelompok />
              </PrivateRoute>
            ),
          },
          {
            exact: true,
            path: "/master-data-neraca/kelompok/ubah/:id",
            element: (
              <PrivateRoute
                userType={[USER_TYPE.SUPER_USER, USER_TYPE.ADMIN_BAPPEDA]}
              >
                <UpdateKelompok />
              </PrivateRoute>
            ),
          },
          // JENIS
          {
            exact: true,
            name: "Jenis",
            path: "/master-data-neraca/jenis",
            element: (
              <PrivateRoute
                userType={[USER_TYPE.SUPER_USER, USER_TYPE.ADMIN_BAPPEDA]}
              >
                <MasterJenis />
              </PrivateRoute>
            ),
          },
          {
            exact: true,
            path: "/master-data-neraca/jenis/tambah",
            element: (
              <PrivateRoute
                userType={[USER_TYPE.SUPER_USER, USER_TYPE.ADMIN_BAPPEDA]}
              >
                <CreateJenis />
              </PrivateRoute>
            ),
          },
          {
            exact: true,
            path: "/master-data-neraca/jenis/ubah/:id",
            element: (
              <PrivateRoute
                userType={[USER_TYPE.SUPER_USER, USER_TYPE.ADMIN_BAPPEDA]}
              >
                <UpdateJenis />
              </PrivateRoute>
            ),
          },
          // NERACA OBJEK
          {
            exact: true,
            name: "Objek",
            path: "/master-data-neraca/objek",
            element: (
              <PrivateRoute
                userType={[USER_TYPE.SUPER_USER, USER_TYPE.ADMIN_BAPPEDA]}
              >
                <MasterNeracaObjek />
              </PrivateRoute>
            ),
          },
          {
            exact: true,
            path: "/master-data-neraca/objek/tambah",
            element: (
              <PrivateRoute
                userType={[USER_TYPE.SUPER_USER, USER_TYPE.ADMIN_BAPPEDA]}
              >
                <CreateNeracaObjek />
              </PrivateRoute>
            ),
          },
          {
            exact: true,
            path: "/master-data-neraca/objek/ubah/:id",
            element: (
              <PrivateRoute
                userType={[USER_TYPE.SUPER_USER, USER_TYPE.ADMIN_BAPPEDA]}
              >
                <UpdateNeracaObjek />
              </PrivateRoute>
            ),
          },
          // RINCIAN OBJEK
          {
            exact: true,
            name: "Rincian Objek",
            path: "/master-data-neraca/rincian-objek",
            element: (
              <PrivateRoute
                userType={[USER_TYPE.SUPER_USER, USER_TYPE.ADMIN_BAPPEDA]}
              >
                <MasterRincianObjek />
              </PrivateRoute>
            ),
          },
          {
            exact: true,
            path: "/master-data-neraca/rincian-objek/tambah",
            element: (
              <PrivateRoute
                userType={[USER_TYPE.SUPER_USER, USER_TYPE.ADMIN_BAPPEDA]}
              >
                <CreateRincianObjek />
              </PrivateRoute>
            ),
          },
          {
            exact: true,
            path: "/master-data-neraca/rincian-objek/ubah/:id",
            element: (
              <PrivateRoute
                userType={[USER_TYPE.SUPER_USER, USER_TYPE.ADMIN_BAPPEDA]}
              >
                <UpdateRincianObjek />
              </PrivateRoute>
            ),
          },
          // SUB RINCIAN OBJEK
          {
            exact: true,
            name: "Sub Rincian Objek",
            path: "/master-data-neraca/sub-rincian-objek",
            element: (
              <PrivateRoute
                userType={[USER_TYPE.SUPER_USER, USER_TYPE.ADMIN_BAPPEDA]}
              >
                <MasterSubRincianObjek />
              </PrivateRoute>
            ),
          },
          {
            exact: true,
            path: "/master-data-neraca/sub-rincian-objek/tambah",
            element: (
              <PrivateRoute
                userType={[USER_TYPE.SUPER_USER, USER_TYPE.ADMIN_BAPPEDA]}
              >
                <CreateSubRincianObjek />
              </PrivateRoute>
            ),
          },
          {
            exact: true,
            path: "/master-data-neraca/sub-rincian-objek/ubah/:id",
            element: (
              <PrivateRoute
                userType={[USER_TYPE.SUPER_USER, USER_TYPE.ADMIN_BAPPEDA]}
              >
                <UpdateSubRincianObjek />
              </PrivateRoute>
            ),
          },
        ],
      },
      // {
      //   name: "Sumber Dana",
      //   exact: true,
      //   children: [
      //     {
      //       name: "Sumber Dana",
      //       exact: true,
      //       path: "/master-data-sumber-dana/sumber-dana",
      //       element: (
      //         <PrivateRoute userType={[USER_TYPE.SUPER_USER, USER_TYPE.ADMIN_BAPPEDA]}>
      //           <MasterSumberDana />
      //         </PrivateRoute>
      //       ),
      //     },
      //     {
      //       exact: true,
      //       path: "/master-data-sumber-dana/sumber-dana/tambah",
      //       element: (
      //         <PrivateRoute userType={[USER_TYPE.SUPER_USER, USER_TYPE.ADMIN_BAPPEDA]}>
      //           <CreateSumberDana />
      //         </PrivateRoute>
      //       ),
      //     },
      //     {
      //       exact: true,
      //       path: "/master-data-sumber-dana/sumber-dana/ubah/:id",
      //       element: (
      //         <PrivateRoute userType={[USER_TYPE.SUPER_USER, USER_TYPE.ADMIN_BAPPEDA]}>
      //           <UpdateSumberDana />
      //         </PrivateRoute>
      //       ),
      //     },
      //     // KELOMPOK
      //     {
      //       name: "Kelompok",
      //       exact: true,
      //       path: "/master-data-sumber-dana/kelompok",
      //       element: (
      //         <PrivateRoute userType={[USER_TYPE.SUPER_USER, USER_TYPE.ADMIN_BAPPEDA]}>
      //           <MasterSumberDanaKelompok />
      //         </PrivateRoute>
      //       ),
      //     },
      //     {
      //       exact: true,
      //       path: "/master-data-sumber-dana/kelompok/tambah",
      //       element: (
      //         <PrivateRoute userType={[USER_TYPE.SUPER_USER, USER_TYPE.ADMIN_BAPPEDA]}>
      //           <CreateSumberDanaKelompok />
      //         </PrivateRoute>
      //       ),
      //     },
      //     {
      //       exact: true,
      //       path: "/master-data-sumber-dana/kelompok/ubah/:id",
      //       element: (
      //         <PrivateRoute userType={[USER_TYPE.SUPER_USER, USER_TYPE.ADMIN_BAPPEDA]}>
      //           <UpdateSumberDanaKelompok />
      //         </PrivateRoute>
      //       ),
      //     },
      //     // JENIS
      //     {
      //       exact: true,
      //       name: "Jenis",
      //       path: "/master-data-sumber-dana/jenis",
      //       element: (
      //         <PrivateRoute userType={[USER_TYPE.SUPER_USER, USER_TYPE.ADMIN_BAPPEDA]}>
      //           <MasterSumberDanaJenis />
      //         </PrivateRoute>
      //       ),
      //     },
      //     {
      //       exact: true,
      //       path: "/master-data-sumber-dana/jenis/tambah",
      //       element: (
      //         <PrivateRoute userType={[USER_TYPE.SUPER_USER, USER_TYPE.ADMIN_BAPPEDA]}>
      //           <CreateSumberDanaJenis />
      //         </PrivateRoute>
      //       ),
      //     },
      //     {
      //       exact: true,
      //       path: "/master-data-sumber-dana/jenis/ubah/:id",
      //       element: (
      //         <PrivateRoute userType={[USER_TYPE.SUPER_USER, USER_TYPE.ADMIN_BAPPEDA]}>
      //           <UpdateSumberDanaJenis />
      //         </PrivateRoute>
      //       ),
      //     },
      //     // RINCIAN OBJEK
      //     {
      //       exact: true,
      //       name: "Objek",
      //       path: "/master-data-sumber-dana/objek",
      //       element: (
      //         <PrivateRoute userType={[USER_TYPE.SUPER_USER, USER_TYPE.ADMIN_BAPPEDA]}>
      //           <MasterSumberDanaObjek />
      //         </PrivateRoute>
      //       ),
      //     },
      //     {
      //       exact: true,
      //       path: "/master-data-sumber-dana/objek/tambah",
      //       element: (
      //         <PrivateRoute userType={[USER_TYPE.SUPER_USER, USER_TYPE.ADMIN_BAPPEDA]}>
      //           <CreateSumberDanaObjek />
      //         </PrivateRoute>
      //       ),
      //     },
      //     {
      //       exact: true,
      //       path: "/master-data-sumber-dana/objek/ubah/:id",
      //       element: (
      //         <PrivateRoute userType={[USER_TYPE.SUPER_USER, USER_TYPE.ADMIN_BAPPEDA]}>
      //           <UpdateSumberDanaObjek />
      //         </PrivateRoute>
      //       ),
      //     },
      //     // RINCIAN OBJEK
      //     {
      //       exact: true,
      //       name: "Rincian Objek",
      //       path: "/master-data-sumber-dana/rincian-objek",
      //       element: (
      //         <PrivateRoute userType={[USER_TYPE.SUPER_USER, USER_TYPE.ADMIN_BAPPEDA]}>
      //           <MasterSumberDanaRincianObjek />
      //         </PrivateRoute>
      //       ),
      //     },
      //     {
      //       exact: true,
      //       path: "/master-data-sumber-dana/rincian-objek/tambah",
      //       element: (
      //         <PrivateRoute userType={[USER_TYPE.SUPER_USER, USER_TYPE.ADMIN_BAPPEDA]}>
      //           <CreateSumberDanaRincianObjek />
      //         </PrivateRoute>
      //       ),
      //     },
      //     {
      //       exact: true,
      //       path: "/master-data-sumber-dana/rincian-objek/ubah/:id",
      //       element: (
      //         <PrivateRoute userType={[USER_TYPE.SUPER_USER, USER_TYPE.ADMIN_BAPPEDA]}>
      //           <UpdateSumberDanaRincianObjek />
      //         </PrivateRoute>
      //       ),
      //     },
      //     // SUB RINCIAN OBJEK
      //     {
      //       exact: true,
      //       name: "Sub Rincian Objek",
      //       path: "/master-data-sumber-dana/sub-rincian-objek",
      //       element: (
      //         <PrivateRoute userType={[USER_TYPE.SUPER_USER, USER_TYPE.ADMIN_BAPPEDA]}>
      //           <MasterSumberDanaSubRincianObjek />
      //         </PrivateRoute>
      //       ),
      //     },
      //     {
      //       exact: true,
      //       path: "/master-data-sumber-dana/sub-rincian-objek/tambah",
      //       element: (
      //         <PrivateRoute userType={[USER_TYPE.SUPER_USER, USER_TYPE.ADMIN_BAPPEDA]}>
      //           <CreateSumberDanaSubRincianObjek />
      //         </PrivateRoute>
      //       ),
      //     },
      //     {
      //       exact: true,
      //       path: "/master-data-sumber-dana/sub-rincian-objek/ubah/:id",
      //       element: (
      //         <PrivateRoute userType={[USER_TYPE.SUPER_USER, USER_TYPE.ADMIN_BAPPEDA]}>
      //           <UpdateSumberDanaSubRincianObjek />
      //         </PrivateRoute>
      //       ),
      //     },
      //   ],
      // },
      {
        name: "Wilayah",
        exact: true,
        path: "/master-data-wilayah",
        element: (
          <PrivateRoute
            userType={[USER_TYPE.SUPER_USER, USER_TYPE.ADMIN_BAPPEDA]}
          ></PrivateRoute>
        ),
      },
      {
        name: "Klasifikasi Kegiatan",
        exact: true,
        path: "/master-data-klasifikasi-kegiatan",
        element: (
          <PrivateRoute
            userType={[USER_TYPE.SUPER_USER, USER_TYPE.ADMIN_BAPPEDA]}
          ></PrivateRoute>
        ),
      },
    ],
  },
  {
    name: "RPJMD",
    icon: "bi bi-graph-up",
    userType: [
      USER_TYPE.SUPER_USER,
      USER_TYPE.ADMIN_BAPPEDA,
      USER_TYPE.USER_OPD,
    ],
    children: [
      {
        name: 'Form E.40',
        path: "/rpjmd/rpjmd-formulir-e40",
        exact: true,
        userType: [
          USER_TYPE.SUPER_USER,
          USER_TYPE.ADMIN_BAPPEDA,
          USER_TYPE.USER_OPD,
        ],
        element: (
          <PrivateRoute
            userType={[
              USER_TYPE.SUPER_USER,
              USER_TYPE.ADMIN_BAPPEDA,
              USER_TYPE.USER_OPD,
            ]}
          >
            <FormulirE40 />
          </PrivateRoute>
        ),
      },
      {
        name: 'Form E.60',
        path: "/rpjmd/rpjmd-formulir-e60",
        exact: true,
        userType: [
          USER_TYPE.SUPER_USER,
          USER_TYPE.ADMIN_BAPPEDA,
          USER_TYPE.USER_OPD,
        ],
        element: (
          <PrivateRoute
            userType={[
              USER_TYPE.SUPER_USER,
              USER_TYPE.ADMIN_BAPPEDA,
              USER_TYPE.USER_OPD,
            ]}
          >
            <FormulirE60 />
          </PrivateRoute>
        ),
      },
    ],
  },
    {
    name: "RKPD",
    icon: "bi bi-graph-up",
    userType: [
      USER_TYPE.SUPER_USER,
      USER_TYPE.ADMIN_BAPPEDA,
      USER_TYPE.USER_OPD,
    ],
    children: [
      {
        name: 'Form E.40',
        path: "/rkpd/rkpd-formulir-e40",
        exact: true,
        userType: [
          USER_TYPE.SUPER_USER,
          USER_TYPE.ADMIN_BAPPEDA,
          USER_TYPE.USER_OPD,
        ],
        element: (
          <PrivateRoute
            userType={[
              USER_TYPE.SUPER_USER,
              USER_TYPE.ADMIN_BAPPEDA,
              USER_TYPE.USER_OPD,
            ]}
          >
            <FormulirE40 />
          </PrivateRoute>
        ),
      },
      {
        name: 'Form E.60',
        path: "/rkpd/rkpd-formulir-e60",
        exact: true,
        userType: [
          USER_TYPE.SUPER_USER,
          USER_TYPE.ADMIN_BAPPEDA,
          USER_TYPE.USER_OPD,
        ],
        element: (
          <PrivateRoute
            userType={[
              USER_TYPE.SUPER_USER,
              USER_TYPE.ADMIN_BAPPEDA,
              USER_TYPE.USER_OPD,
            ]}
          >
            <FormulirE60 />
          </PrivateRoute>
        ),
      },
    ],
  },
  {
    name: "Anggaran",
    path: "/anggaran",
    icon: "bi bi-coin",
    userType: [
      USER_TYPE.SUPER_USER,
      USER_TYPE.ADMIN_BAPPEDA,
      USER_TYPE.USER_OPD,
    ],
    element: (
      <PrivateRoute
        userType={[
          USER_TYPE.SUPER_USER,
          USER_TYPE.ADMIN_BAPPEDA,
          USER_TYPE.USER_OPD,
        ]}
      >
        <AnggaranPages />
      </PrivateRoute>
    ),
  },
  {
    path: "/anggaran/:id/aktivitas",
    userType: [
      USER_TYPE.SUPER_USER,
      USER_TYPE.ADMIN_BAPPEDA,
      USER_TYPE.USER_OPD,
    ],
    element: (
      <PrivateRoute
        userType={[
          USER_TYPE.SUPER_USER,
          USER_TYPE.ADMIN_BAPPEDA,
          USER_TYPE.USER_OPD,
        ]}
      >
        <ListAktivitasSubKegiatan />
      </PrivateRoute>
    ),
  },
  {
    path: "/anggaran/:id/aktivitas/ubah",
    userType: [
      USER_TYPE.USER_OPD,
      USER_TYPE.SUPER_USER,
      USER_TYPE.ADMIN_BAPPEDA,
    ],
    element: (
      <PrivateRoute
        userType={[
          USER_TYPE.USER_OPD,
          USER_TYPE.SUPER_USER,
          USER_TYPE.ADMIN_BAPPEDA,
        ]}
      >
        <EditAktivitasSubKegiatan />
      </PrivateRoute>
    ),
  },
  // {
  //   name: "Data Transaksi",
  //   icon: "bi bi-file-earmark-text",
  //   userType: [USER_TYPE.SUPER_USER],
  //   children: [
  //     {
  //       name: "Overview",
  //       exact: true,
  //       path: "/master-data-transaction",
  //       element: (
  //         <PrivateRoute userType={[USER_TYPE.SUPER_USER]}>
  //           <MasterTransaction />
  //         </PrivateRoute>
  //       ),
  //     },
  //   ],
  // },
  {
    name: "Pemantauan/Pelaporan",
    icon: "bi bi-file-earmark-richtext",
    path: "/pelaporan",
    userType: [
      USER_TYPE.SUPER_USER,
      USER_TYPE.ADMIN_BAPPEDA,
      USER_TYPE.USER_OPD,
    ],
    element: (
      <PrivateRoute
        userType={[
          USER_TYPE.SUPER_USER,
          USER_TYPE.ADMIN_BAPPEDA,
          USER_TYPE.USER_OPD,
        ]}
      >
        <PelaporanSubKegiatan />
      </PrivateRoute>
    ),
  },
  {
    path: "/pelaporan/:id/progress",
    userType: [
      USER_TYPE.SUPER_USER,
      USER_TYPE.ADMIN_BAPPEDA,
      USER_TYPE.USER_OPD,
    ],
    element: (
      <PrivateRoute
        userType={[
          USER_TYPE.SUPER_USER,
          USER_TYPE.ADMIN_BAPPEDA,
          USER_TYPE.USER_OPD,
        ]}
      >
        <PelaporanProgresBulananList />
      </PrivateRoute>
    ),
  },
  {
    path: "/pelaporan/:id/tambah-progress-bulanan",
    userType: [
      USER_TYPE.USER_OPD,
      USER_TYPE.SUPER_USER,
      USER_TYPE.ADMIN_BAPPEDA,
    ],
    element: (
      <PrivateRoute
        userType={[
          USER_TYPE.USER_OPD,
          USER_TYPE.SUPER_USER,
          USER_TYPE.ADMIN_BAPPEDA,
        ]}
      >
        <PelaporanProgresBulananAdd />
      </PrivateRoute>
    ),
  },
  {
    path: "/pelaporan/:pelaporan_id/edit-progress-bulanan/:progress_id",
    userType: [
      USER_TYPE.USER_OPD,
      USER_TYPE.SUPER_USER,
      USER_TYPE.ADMIN_BAPPEDA,
    ],
    element: (
      <PrivateRoute
        userType={[
          USER_TYPE.USER_OPD,
          USER_TYPE.SUPER_USER,
          USER_TYPE.ADMIN_BAPPEDA,
        ]}
      >
        <PelaporanProgresBulananEdit />
      </PrivateRoute>
    ),
  },
  {
    name: "User",
    icon: "bi bi-people",
    path: "/daftar-user",
    userType: [USER_TYPE.SUPER_USER, USER_TYPE.ADMIN_BAPPEDA],
    element: (
      <PrivateRoute userType={[USER_TYPE.SUPER_USER, USER_TYPE.ADMIN_BAPPEDA]}>
        <ListUser />
      </PrivateRoute>
    ),
  },
  {
    exact: true,
    path: "/tambah-user",
    userType: [USER_TYPE.SUPER_USER, USER_TYPE.ADMIN_BAPPEDA],
    element: (
      <PrivateRoute userType={[USER_TYPE.SUPER_USER, USER_TYPE.ADMIN_BAPPEDA]}>
        <CreateUser />
      </PrivateRoute>
    ),
  },
  {
    exact: true,
    path: "/ubah-user/:id",
    userType: [USER_TYPE.SUPER_USER, USER_TYPE.ADMIN_BAPPEDA],
    element: (
      <PrivateRoute>
        <UpdateUser />
      </PrivateRoute>
    ),
  },
  {
    name: "Pengaturan",
    icon: "bi bi-terminal",
    userType: [USER_TYPE.SUPER_USER, USER_TYPE.ADMIN_BAPPEDA],
    children: [
      {
        name: "Periode Upload",
        exact: true,
        path: "/periode-upload",
        element: (
          <PrivateRoute
            userType={[USER_TYPE.SUPER_USER, USER_TYPE.ADMIN_BAPPEDA]}
          >
            <PeriodeUpload />
          </PrivateRoute>
        ),
      },
    ],
  },
];
