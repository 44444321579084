const LoadingGlobal = () => {
  return (
    <div className="load-1">
      <div className="line"></div>
      <div className="line"></div>
      <div className="line"></div>
    </div>
  );
};

export default LoadingGlobal;
