const DUMMY_DATAS_TABLE = [
  {
    no: "1.",
    nama_kegiatan: "Pengembangan Kawasan",
    opd: "Dinas Pertanian",
    deadline: "23-01-2022",
    status: "Sedang dikerjakan",
    penyedia_jasa: "CV. Harapan Jasa",
    progres: 38,
  },
  {
    no: "2.",
    nama_kegiatan: "Pengembangan Kawasan",
    opd: "Dinas Pertanian",
    deadline: "23-01-2022",
    status: "Sedang dikerjakan",
    penyedia_jasa: "CV. Harapan Jasa",
    progres: 38,
  },
  {
    no: "3.",
    nama_kegiatan: "Pengembangan Kawasan",
    opd: "Dinas Pertanian",
    deadline: "23-01-2022",
    status: "Sedang dikerjakan",
    penyedia_jasa: "CV. Harapan Jasa",
    progres: 38,
  },
  {
    no: "4.",
    nama_kegiatan: "Pengembangan Kawasan",
    opd: "Dinas Pertanian",
    deadline: "23-01-2022",
    status: "Sedang dikerjakan",
    penyedia_jasa: "CV. Harapan Jasa",
    progres: 38,
  },
  {
    no: "5.",
    nama_kegiatan: "Pengembangan Kawasan",
    opd: "Dinas Pertanian",
    deadline: "23-01-2022",
    status: "Sedang dikerjakan",
    penyedia_jasa: "CV. Harapan Jasa",
    progres: 38,
  },
];

const CHANGELOG = [
  {
    name: "Menghapus font inter",
  },
  {
    name: "Menambahkan logo",
  },
  {
    name: "Menambahkan dummy data table",
  },
  {
    name: "Menambahkan perfect scrollbar pada sidebar",
  },
  {
    name: "Menambahkan modal update app",
  },
];

const DATA_TYPE = {
  STRING: "string",
  UNDEFINED: "undefined",
};

const STATUS_CODE = {
  200: 200,
  400: 400,
  409: 409,
  404: 404,
  422: 422,
  500: 500,
};

const USER_TYPE = {
  SUPER_USER: 1,
  ADMIN_BAPPEDA: 2,
  USER_OPD: 3,
  LISTS: [
    {
      label: "Superadmin",
      value: 1,
    },
    {
      label: "Admin Bappeda",
      value: 2,
    },
    {
      label: "OPD",
      value: 3,
    },
  ],
};

const INPUT_TYPE = {
  INPUT: "input",
  TEXT: "text",
  TEXTAREA: "textarea",
  SELECT: "select",
  PASSWORD: "password",
  SWITCH: "switch",
  PRICE: "price",
  SELECT_SYNC: "select_sync",
  SELECT_CREATABLE: "select_creatable",
  UPLOAD_IMAGE: "upload_image",
  TEXT_EDITOR: "text_editor",
  RADIO: "radio",
  COLOR: "color",
  YEAR: "year",
  DATE: "date",
  IMAGE: "image",
  PERCENT: "percent",
};

const DROPDOWN_TYPE_USER = [
  {
    id: 1,
    label: "SUPER ADMIN",
  },
  {
    id: 2,
    label: "ADMIN BAPPEDA",
  },
  {
    id: 3,
    label: "ADMIN OPD",
  },
];

const REACT_SELECT_CUSTOM_STYLES = {
  menu: (styles) => ({
    ...styles,
    borderRadius: 0,
  }),
  placeholder: (styles) => ({
    ...styles,
    color: "#8898a9",
    marginLeft: ".75rem",
  }),
  input: (styles) => ({
    ...styles,
    marginLeft: ".75rem",
    padding: ".35rem 0 .35rem 0",
    boxShadow: "none",
  }),
  option: (styles, { isSelected, isFocused }) => ({
    ...styles,
    backgroundColor: isSelected ? "#5c60f5" : isFocused ? "#5c60f5" : undefined,
    color: isFocused ? "#fff" : isSelected ? "#fff" : undefined,
  }),
};

const LAYER_OPTIONS = [
  {
    label: "DESA",
    value: "desa",
  },
  {
    label: "KECAMATAN",
    value: "kecamatan",
  },
];

const DEFAULT_CHOOSE = [
  {
    label: "SEMUA",
    value: "semua",
  },
];

const TIPE_LAYER = {
  POINT: "Point",
  LINE_STRING: "LineString",
  POLYGON: "Polygon",
  POLYLINE: "Polyline",
  MULTILINESTRING: "MultiLineString",
};

const MONTHS = [
  {
    label: "Januari",
    value: 1,
  },
  {
    label: "Februari",
    value: 2,
  },
  {
    label: "Maret",
    value: 3,
  },
  {
    label: "April",
    value: 4,
  },
  {
    label: "Mei",
    value: 5,
  },
  {
    label: "Juni",
    value: 6,
  },
  {
    label: "Juli",
    value: 7,
  },
  {
    label: "Agustus",
    value: 8,
  },
  {
    label: "September",
    value: 9,
  },
  {
    label: "Oktober",
    value: 10,
  },
  {
    label: "November",
    value: 11,
  },
  {
    label: "Desember",
    value: 12,
  },
];

const BASE_MAP = {
  GOOGLE_MAP: "GOOGLE_MAP",
  GOOGLE_SATELITE: "GOOGLE_SATELITE",
};

const LEGENDS_AKTIVITAS = {
  0: "red",
  50: "orange",
  100: "green",
  LISTS: [
    {
      label: "0%",
      value: "red",
    },
    {
      label: "50%",
      value: "orange",
    },
    {
      label: "100%",
      value: "green",
    },
  ],
};

const CENTER_MAP = [-1.1077600040995492, 134.31037070844107];

export {
  DUMMY_DATAS_TABLE,
  CHANGELOG,
  DATA_TYPE,
  STATUS_CODE,
  USER_TYPE,
  INPUT_TYPE,
  DROPDOWN_TYPE_USER,
  REACT_SELECT_CUSTOM_STYLES,
  LAYER_OPTIONS,
  DEFAULT_CHOOSE,
  TIPE_LAYER,
  MONTHS,
  BASE_MAP,
  LEGENDS_AKTIVITAS,
  CENTER_MAP,
};
