import {
  SET_MENU,
  SET_TABLE,
  SET_ERRORS,
  SET_TRIGGER_TABLE,
  SET_FROM_LOGIN,
  SET_MINI_MENU,
} from "./type";

export const setMenu = (menu) => ({
  type: SET_MENU,
  payload: menu,
});

export const setErrors = (errors) => ({
  type: SET_ERRORS,
  payload: errors,
});

export const setTriggerTable = (trigger) => ({
  type: SET_TRIGGER_TABLE,
  payload: trigger,
});

export const setFromLogin = (fromLogin) => ({
  type: SET_FROM_LOGIN,
  payload: fromLogin,
});

export const setMiniMenu = (miniMenu) => ({
  type: SET_MINI_MENU,
  payload: miniMenu,
});

export const setTable = (tableData) => ({
  type: SET_TABLE,
  payload: tableData,
});
