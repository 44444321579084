import PropTypes from "prop-types";

const Checkbox = ({ label, ...rest }) => {
  return (
    <div className="form-check form-check-linethrough d-flex">
      <input
        className="form-check-input w-5 h-5 mt-0 rounded-circle border-dashed flex-none"
        type="checkbox"
        {...rest}
      />
      <p className="form-check-label font-regular ms-2">{label}</p>
    </div>
  );
};

Checkbox.propTypes = {
  label: PropTypes.string,
};

export default Checkbox;
