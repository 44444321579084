import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Button } from "react-bootstrap";
import PropTypes from "prop-types";
import Checkbox from "./checkbox";

const ModalUpdateApp = ({ onClick, type }) => {
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(true);

  const { changelog } = useSelector((state) => state.app);

  useEffect(() => {
    if (!onClick) {
      const timer = setTimeout(() => {
        dispatch({ type });
      }, 1000);

      return () => clearTimeout(timer);
    }
  }, []);

  if (onClick) {
    return (
      <Modal show={openModal} centered onHide={() => setOpenModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Aplikasi berhasil diperbaharui!</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <h5 className="font-semibold mb-5">
            <i className="bi bi-journals me-1"></i>
            <span>Changelog</span>
          </h5>

          <div className="d-flex flex-column gap-3">
            {changelog.map((log) => (
              <Checkbox key={log.name} label={log.name} checked />
            ))}
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="neutral">Batal</Button>
          <Button variant="primary">Perbaharui</Button>
        </Modal.Footer>
      </Modal>
    );
  }

  return null;
};

ModalUpdateApp.propTypes = {
  type: PropTypes.string,
  onClick: PropTypes.func,
};

export default ModalUpdateApp;
