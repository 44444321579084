import { SET_USER_DATA, REMOVE_USER_DATA } from "./type";

const authState = {
  user: null,
};

const authReducers = (state = authState, action) => {
  switch (action.type) {
    case SET_USER_DATA:
      return { ...state, user: { ...state.user, ...action.payload } };
    case REMOVE_USER_DATA:
      return { ...state, user: null };
    default:
      return state;
  }
};

export default authReducers;
