import { StrictMode } from "react";
import { Provider } from "react-redux";
import { render } from "react-dom";
import { storeConfiguration } from "./redux";
import { SW_INIT, SW_UPDATE } from "redux/app/type";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorker from "./serviceWorkerRegistration";
import "bootstrap-icons/font/bootstrap-icons.css";
import "./css/index.css";

const store = storeConfiguration();

render(
  <StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </StrictMode>,
  document.getElementById("root")
);

reportWebVitals();

serviceWorker.register({
  onSuccess: () => store.dispatch({ type: SW_INIT }),
  onUpdate: (registration) =>
    store.dispatch({ type: SW_UPDATE, payload: registration }),
});
